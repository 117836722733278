import { useRef } from 'react';
import { MediaPostListItem } from '../../types';
import { Advertisement, AdvertisementId } from '../Advertisement';
import { MediaBlock } from './components/MediaBlock';
import { useMasonryGroupResize } from '../../react-hooks/useMasonryGroupResize';
import { MAX_ARTICLE_SIZE } from './components/Masonry';

interface PostBlocksProps {
  blocks: MediaPostListItem[][];
  className?: string;
}

const PostBlocks = ({ blocks, className }: PostBlocksProps) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useMasonryGroupResize(rootRef, MAX_ARTICLE_SIZE);

  return (
    <div className={className} ref={rootRef}>
      {blocks.map((block) => {
        const [superfeature, ...features] = block;

        return superfeature ? (
          <div className="flex w-full flex-col" key={superfeature.id}>
            <MediaBlock feature={superfeature} articles={features} />
            <Advertisement
              className="mx-0 my-5.5 hidden self-center md:flex"
              id={AdvertisementId.DesktopMainPageContentBillboard}
            />
          </div>
        ) : null;
      })}
    </div>
  );
};

export { PostBlocks };
