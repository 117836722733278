import { MediaStats } from '../../MediaStats';

interface Stats {
  isPublished: boolean;
  commentsCount: number;
}

const Meta = ({ commentsCount, isPublished }: Stats): JSX.Element => (
  <div className="ml-1 inline-flex justify-between text-sm">
    {isPublished ? <MediaStats comments={commentsCount} /> : <p>Не опубл.</p>}
  </div>
);

export { Meta };
export type { Stats };
