import { useMediaQuery } from '@flame-frontend-utils/commons';
import { Fragment } from 'react';
import { mediaWidth } from '../../../styles/width';
import { MASONRY_SPACER, MASONRY_CONTAINER } from '../../../react-hooks/useMasonryGroupResize';
import { MediaPostListItem } from '../../../types';
import { Advertisement, AdvertisementId, useRepeats } from '../../Advertisement';
import {
  CARD_PADDING_BOTTOM,
  CARD_PADDING_BOTTOM_DESKTOP,
  CARD_PADDING_RIGHT,
  CARD_PADDING_TOP_DESKTOP,
  MediaCard,
} from '../../MediaCard';
import { tw } from '../../../styles/tw';

declare module 'react' {
  interface CSSProperties {
    '--height'?: string;
    '--card-padding-right'?: string;
    '--card-padding-top-desktop'?: string;
    '--card-padding-bottom'?: string;
    '--card-padding-bottom-desktop'?: string;
  }
}

interface Props {
  articles: MediaPostListItem[];
}

const MAX_ARTICLE_SIZE = 652;

const Masonry = ({ articles }: Props): JSX.Element => {
  const repeats = useRepeats(AdvertisementId.MobileMainPageInContent);
  const isAboveMobile = useMediaQuery(mediaWidth.m, { initialValue: true });

  return (
    <div
      style={{
        '--height': `${Math.ceil((articles.length / 2) * MAX_ARTICLE_SIZE) / 16}rem`,
        '--card-padding-right': `${CARD_PADDING_RIGHT / 16}rem`,
        '--card-padding-top-desktop': `${CARD_PADDING_TOP_DESKTOP / 16}rem`,
        '--card-padding-bottom': `${CARD_PADDING_BOTTOM / 16}rem`,
        '--card-padding-bottom-desktop': `${CARD_PADDING_BOTTOM_DESKTOP / 16}rem`,
      }}
      className={tw(
        'w-full flex-col flex-wrap md:h-[var(--height)] md:gap-x-[var(--card-padding-right)] lg:min-h-[calc(100vh_-_var(--header-height))] lg:flex-nowrap xl:flex-wrap',
        !articles.length ? 'hidden' : 'block md:flex',
        MASONRY_CONTAINER
      )}
    >
      <div
        className={tw(
          'order-2 mb-[var(--card-padding-bottom)] mt-[var(--card-padding-top-desktop)] hidden w-px basis-full bg-blood md:mb-[var(--card-padding-bottom-desktop)] md:block lg:hidden xl:block',
          MASONRY_SPACER
        )}
        key={2}
      />
      {articles.map((article, index) => (
        <Fragment key={article.id}>
          <MediaCard className={masonryCard} key={article.id} article={article} />
          {getIsAdVisible(repeats, index) && !isAboveMobile ? (
            <Advertisement className="mx-auto my-4 md:hidden" id={AdvertisementId.MobileMainPageInContent} />
          ) : null}
          {index === getMasonryAdIndex(articles.length) ? (
            <Advertisement className={tw(masonryCard, 'hidden md:flex')} id={AdvertisementId.DesktopMainSideBanner} />
          ) : null}
        </Fragment>
      ))}
      {articles.length < 1 && <div className={tw(masonryCard, 'hidden md:block')} />}
    </div>
  );
};

function getIsAdVisible(repeats: number | null, cardIndex: number): boolean {
  return Boolean(repeats && !((cardIndex + 1) % repeats));
}

function getMasonryAdIndex(length: number): number {
  if (PUBLIC_CONFIG.MASONRY_AD_INDEX < 0) {
    return Math.max(0, length + PUBLIC_CONFIG.MASONRY_AD_INDEX);
  }
  return Math.min(length - 1, PUBLIC_CONFIG.MASONRY_AD_INDEX);
}

const masonryCard = tw(
  'md:w-[calc(50%_-_var(--card-padding-right))] lg:w-full xl:w-[calc(50%_-_var(--card-padding-right))] md:[&:nth-child(2n+2)]:order-1 md:[&:nth-child(2n+3)]:order-2 lg:[&:nth-child(2n+3)]:order-1 xl:[&:nth-child(2n+3)]:order-2'
);

export { Masonry, MAX_ARTICLE_SIZE };
