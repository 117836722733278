import { CSSProperties } from 'react';
import { Stats } from './components/Meta';
import { MediaPostListItem } from '../../types';
import { Feature } from './components/Feature';
import { PostStatus } from '../../lib/graphql.document';
import { ROUTES } from '../../lib/ROUTES';
import { Clickable } from '../Clickable';
import { tw } from '../../styles/tw';
import { appendSearchParams } from '../../lib/appendSearchParams';
import { HIDDEN_PAID_POSTS } from '../../lib/HIDDEN_PAID_POSTS';

interface MediaCardProps {
  article: MediaPostListItem;
  isPromoted?: boolean;
  className?: string;
  style?: CSSProperties;
}

const MediaCard = ({ article, isPromoted, className, style }: MediaCardProps): JSX.Element => {
  const stats: Stats = {
    isPublished: article.status === PostStatus.Published,
    commentsCount: article.commentsCount,
  };

  return (
    <div style={style} className={tw('flex w-full flex-col', className)}>
      <Clickable
        className="[align-items:normal] [justify-content:normal] [&>section]:w-full [&_*]:transition-colors [&_*]:duration-75 [&_*]:ease-in-out [@media(hover:hover){&:hover_h2}]:text-text-secondary"
        to={appendSearchParams(ROUTES.POST.buildPath({ slug: article.slug }), article.searchParams)}
      >
        <Feature
          isLarge={Boolean(isPromoted)}
          species={article.species}
          stats={stats}
          title={article.title}
          subtitle={article.subtitle}
          cover={article.cover}
          isPaid={article.isSpecialProject && !HIDDEN_PAID_POSTS.includes(article.id)}
          status={article.status}
        />
      </Clickable>
    </div>
  );
};

export { MediaCard };
export type { MediaCardProps };
