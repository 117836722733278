import { memo, CSSProperties } from 'react';
import { Masonry } from './Masonry';
import {
  CARD_PADDING_BOTTOM,
  CARD_PADDING_BOTTOM_DESKTOP,
  CARD_PADDING_RIGHT,
  CARD_PADDING_TOP_DESKTOP,
  MediaCard,
} from '../../MediaCard';
import { MediaPostListItem } from '../../../types';
import { Advertisement, AdvertisementId } from '../../Advertisement';
import { tw } from '../../../styles/tw';

declare module 'react' {
  interface CSSProperties {
    '--card-padding-right'?: string;
    '--card-padding-bottom'?: string;
    '--card-padding-bottom-desktop'?: string;
    '--card-padding-top-desktop'?: string;
  }
}

interface Props {
  feature: MediaPostListItem;
  articles: MediaPostListItem[];
  className?: string;
  style?: CSSProperties;
}

const MediaBlock = memo(
  ({ feature, articles, className, style }: Props): JSX.Element => (
    <div
      style={{
        '--card-padding-top-desktop': `${CARD_PADDING_TOP_DESKTOP / 16}rem`,
        '--card-padding-right': `${CARD_PADDING_RIGHT / 16}rem`,
        '--card-padding-bottom': `${CARD_PADDING_BOTTOM / 16}rem`,
        '--card-padding-bottom-desktop': `${CARD_PADDING_BOTTOM_DESKTOP / 16}rem`,
        ...style,
      }}
      className={tw(
        'flex w-full flex-col border-blood md:border-b lg:flex-row lg:gap-[var(--card-padding-right)]',
        className
      )}
    >
      <MediaCard
        className="lg:sticky lg:top-[var(--header-height)] lg:w-[calc(66.6%_-_var(--card-padding-right))] lg:shrink-0 lg:self-start lg:border-b-0 xl:w-[calc(50%_-_var(--card-padding-right))]"
        article={feature}
        isPromoted
      />
      <Advertisement className="mx-auto my-4 md:hidden" id={AdvertisementId.MobileMainPageAfterCover} />
      <div className="lg:mb-[var(--card-padding-bottom-desktop)] lg:mt-[var(--card-padding-top-desktop)] lg:w-px lg:shrink-0 lg:bg-blood" />
      <Masonry
        key={articles.reduce((combinedKey, nextArticle) => combinedKey + nextArticle.id, '')}
        articles={articles}
      />
    </div>
  ),
  (prevProps, nextProps) => {
    const sameFirstArticle = prevProps.articles[0]?.id === nextProps.articles[0]?.id;
    const sameFeature = prevProps.feature.id === nextProps.feature.id;

    return sameFirstArticle && sameFeature;
  }
);

export { MediaBlock };
