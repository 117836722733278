import { MediaPostListItem } from '../../types';

interface GetBlocksProps {
  posts: MediaPostListItem[];
  hasNextPage: boolean;
  blockSize: number;
}

export function getBlocks({ posts, blockSize, hasNextPage }: GetBlocksProps) {
  const postsWithPins = [...posts];
  const blocks: MediaPostListItem[][] = [];

  while (postsWithPins.length >= blockSize || (postsWithPins.length > 0 && !hasNextPage)) {
    blocks.push(postsWithPins.splice(0, blockSize));
  }

  return blocks;
}
