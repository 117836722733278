import { RefObject } from 'react';
import { assertIsDefined, useMediaQuery, getDefined } from '@flame-frontend-utils/commons';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { useLayoutEffectWithoutSsrWarning } from './useLayoutEffectWithoutSsrWarning';
import { mediaWidth } from '../styles/width';

const MASONRY_SPACER = 'masonry-spacer';
const MASONRY_CONTAINER = 'masonry-container';

const useMasonryGroupResize = (rootRef: RefObject<HTMLElement>, maxCardHeight: number) => {
  const isAboveMobile = useMediaQuery(mediaWidth.m, { unsafe: true });

  useLayoutEffectWithoutSsrWarning(() => {
    const root = rootRef.current;
    assertIsDefined(root);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

    const resizeObserver = new ResizeObserver((entries) => {
      const affectedContainers: Set<HTMLElement> = new Set();

      entries.forEach((entry) => {
        if (entry.target.parentElement instanceof HTMLElement) affectedContainers.add(entry.target.parentElement);
      });

      recalculateMasonryHeights([...affectedContainers], maxCardHeight);
    });

    const onChildrenAdded = (mutations?: MutationRecord[]) => {
      if (!mutations) {
        const masonryContainers = getInnerMasonryContainers(root);

        recalculateMasonryHeights(masonryContainers, maxCardHeight);

        resizeObserver.disconnect();

        masonryContainers.forEach((container) => {
          [...container.children].forEach((child) => resizeObserver.observe(child));
        });
      } else {
        const addedMasonryContainers: HTMLElement[] = [];
        const removedMasonryContainers: HTMLElement[] = [];

        mutations.forEach((mutation) => {
          addedMasonryContainers.push(...getMasonryContainers(mutation.addedNodes));
          removedMasonryContainers.push(...getMasonryContainers(mutation.removedNodes));
        });

        recalculateMasonryHeights(addedMasonryContainers, maxCardHeight);

        addedMasonryContainers.forEach((container) => {
          [...container.children].forEach((child) => resizeObserver.observe(child));
        });
        removedMasonryContainers.forEach((container) => {
          [...container.children].forEach((child) => resizeObserver.unobserve(child));
        });
      }
    };

    const mutationObserver = new MutationObserver(onChildrenAdded);

    if (isAboveMobile) {
      onChildrenAdded();

      mutationObserver.observe(root, { childList: true });
    }

    return () => {
      getInnerMasonryContainers(root).forEach((container) => {
        clearRootHeight(container);
      });

      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [isAboveMobile, rootRef, maxCardHeight]);
};

function recalculateMasonryHeights(masonryContainers: HTMLElement[], maxCardHeight: number) {
  const cardsNumberList: number[] = [];
  const lastChildrenList: HTMLElement[][] = [];

  const childrenBottomEdgesList: number[][] = [];
  const rootBoundsTopList: number[] = [];

  masonryContainers.forEach((container) => {
    const columns = getColumns(container);

    const cardsNumber = Math.ceil(container.children.length / columns);

    const children = [...container.children].filter(
      ({ classList }) => !classList.contains(MASONRY_SPACER)
    ) as HTMLElement[];
    const lastChildren = [...children].slice(-columns);

    cardsNumberList.push(cardsNumber);
    lastChildrenList.push(lastChildren);
  });

  masonryContainers.forEach((container, index) => {
    setInitialRootHeight(
      container,
      getDefined(
        cardsNumberList[index],
        'Each container is expected to have its cardsNumber calculated during the previous step.'
      ),
      maxCardHeight
    );
  });

  masonryContainers.forEach((container, index) => {
    const childrenBottomEdges = getDefined(
      lastChildrenList[index],
      'Each container is expected to have its lastChildren calculated during the previous step.'
    ).map((child) => child.getBoundingClientRect().bottom);
    const rootBoundsTop = container.getBoundingClientRect().top;

    childrenBottomEdgesList.push(childrenBottomEdges);
    rootBoundsTopList.push(rootBoundsTop);
  });

  masonryContainers.forEach((container, index) => {
    setOptimumRootHeight(
      container,
      getDefined(
        childrenBottomEdgesList[index],
        'Each container is expected to have its childrenBottomEdges calculated during the previous step.'
      ),
      getDefined(
        rootBoundsTopList[index],
        'Each container is expected to have its rootBoundsTop calculated during the previous step.'
      )
    );
  });
}

function getMasonryContainers(elements: Element[] | NodeList): HTMLElement[] {
  const result: HTMLElement[] = [];

  elements.forEach((removedNode) => {
    if (removedNode instanceof HTMLElement) {
      if (removedNode.classList.contains(MASONRY_CONTAINER)) {
        result.push(removedNode);
      } else {
        result.push(...getInnerMasonryContainers(removedNode));
      }
    }
  });

  return result;
}

function getInnerMasonryContainers(root: HTMLElement): HTMLElement[] {
  return [...root.querySelectorAll(`.${MASONRY_CONTAINER}`)] as HTMLElement[];
}

function getColumns(root: HTMLElement) {
  return ([...root.children] as HTMLElement[]).reduce((max, current) => {
    const currentOrder = parseInt(getComputedStyle(current).order, 10);
    const isVisible = getComputedStyle(current).display !== 'none';

    if (currentOrder > max && isVisible) {
      return currentOrder;
    }

    return max;
  }, 0);
}

function clearRootHeight(root: HTMLElement) {
  // eslint-disable-next-line no-param-reassign
  root.style.height = '';
  // eslint-disable-next-line no-param-reassign
  root.style.marginBottom = '';
}

function setInitialRootHeight(root: HTMLElement, cardsNumber: number, maxCardHeight: number) {
  // eslint-disable-next-line no-param-reassign
  root.style.height = `${cardsNumber * maxCardHeight}px`;
  // eslint-disable-next-line no-param-reassign
  root.style.marginBottom = '';
}

function setOptimumRootHeight(root: HTMLElement, childrenBottomEdges: number[], rootBoundsTop: number) {
  const bottomOfLowestChild = Math.max(...childrenBottomEdges);

  /** This is known to break for some fractional widths, so we add 1px to fix these cases. */
  // eslint-disable-next-line no-param-reassign
  root.style.height = `${bottomOfLowestChild - rootBoundsTop + 1}px`;
  /** And we compensate this added pixel so everything looks normal */
  // eslint-disable-next-line no-param-reassign
  root.style.marginBottom = '-1px';
}

export { useMasonryGroupResize, MASONRY_SPACER, MASONRY_CONTAINER };
